import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {FooterComponent} from '@app/shared/ui-kits/footer/footer.component';
import {HeaderComponent} from "@app/shared/ui-kits/header/header.component";
import {TmPreviewComponent} from "@app/shared/ui-kits/tm-preview/tm-preview.component";

@Component({
  selector: 'sf-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  imports: [
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    TmPreviewComponent
  ]
})
export default class MainLayoutComponent {
}

